<template>
  <span v-if="value" class="label-base bg-black text-white text-sm" @click="showBlacklist(value)">BLACKLISTED</span>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
  value: [String, Boolean],
})

const showBlacklist = blacklistReason => {
  if (!blacklistReason) return
  if (typeof blacklistReason === 'string' && blacklistReason?.length) {
    return alert(`Blacklisted met opmerking:\n${blacklistReason}`)
  }
  return alert('Blacklisted zonder opmerking')
}
</script>
